import React, { useState, useEffect, useMemo, useCallback } from "react";
import { DateRange } from "react-date-range";
import { ptBR } from "react-date-range/dist/locale";
import endOfDay from "date-fns/endOfDay";
import startOfDay from "date-fns/startOfDay";
import format from "date-fns/format";

import { Bar, Line, Pie } from "react-chartjs-2";
import BoxScore from "./BoxScore";
import Swal from "sweetalert2";
import HistoricTable from "./HistoricTable";

import ReactLoading from "react-loading";
import Export from "./Export";
import { getColor, convertDateToString } from "utils/globalFunctions";
import { months, slaughterConfig } from "utils/globalData";
import { api } from "services/api";
import { SubHeader } from "components/SubHeader";
import { Icons } from "components/Icons";
import { Input } from "components/Input";
import {
  Block,
  BlockBody,
  BlockHeader,
  Col,
  Row,
  Separator,
  BlockBodyContent,
} from "styles";
import { HistoricOption, HistoricOptionContent, ReportButton } from "./style";

export function Report(props) {
  const initialFarmData = useMemo(
    () => ({
      _id: "",
      customer: "",
      health: {
        anticoccidian: {
          moleculePre: "",
          moleculeInitial: "",
          moleculeGrowth: "",
          moleculeGrowth2: "",
          moleculeFinal: "",
          dosePre: "",
          doseInitial: "",
          doseGrowth: "",
          doseGrowth2: "",
          doseFinal: "",
        },
        performanceEnhancer: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
      },
      additions: {
        organicAcids: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        mycotoxinAdsorbents: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        essencialOils: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        prebiotics: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
        probiotics: {
          pre: {
            product: "",
            dose: "",
          },
          initial: {
            product: "",
            dose: "",
          },
          growth: {
            product: "",
            dose: "",
          },
          growth2: {
            product: "",
            dose: "",
          },
          final: {
            product: "",
            dose: "",
          },
        },
      },
    }),
    []
  );

  const filters = [
    { value: "all", label: "Galpão" },
    { value: "farmDescription", label: "Descrição Granja" },
    { value: "cityRegion", label: "Municipio/região" },
    { value: "technician", label: "Técnico" },
    { value: "eqCatch", label: "Eq Apanha" },
    { value: "lineage", label: "Linhagem" },
  ];

  const farmDataFilters = [
    { value: "matrixLot", label: "Lote de matriz" },
    { value: "batchAge", label: "Idade do lote" },
    { value: "averegeBatchWeight", label: "Peso médio do lote (vivo/ave kg)" },
    {
      value: "dailyBreastYieldPercent",
      label: "Rendimento de peito do dia (%)",
    },
    { value: "batchUniformtyPercent", label: "Uniformidade do lote (%)" },
    { value: "lotDensity", label: "Densidade do lote" },
  ];

  const broiler = [
    { label: "Fitase", value: "enzymes.phytaseHidosing" },
    { label: "Corboidrase/Blends", value: "enzymes.carbohydraseBlends" },
    { label: "EnzyPac Pro / Protease", value: "enzymes.enziPacProProtease" },
    { label: "Anticoccidiano", value: "health.anticoccidian" },
    { label: "Medicação Terapeutica", value: "health.therapeuticMedication" },
    { label: "Melhorador de Desempenho", value: "health.performanceEnhancer" },
    { label: "Minerais Orgânicos", value: "minerals.organics" },
    { label: "Ácidos Orgânicos", value: "additions.organicAcids" },
    {
      label: "Adsorvente de Micotoxina",
      value: "additions.mycotoxinAdsorbents",
    },
    { label: "Óleos Essenciais", value: "additions.essencialOils" },
    { label: "Prebióticos", value: "additions.prebiotics" },
    { label: "Probióticos", value: "additions.probiotics" },
  ];

  const selections = useMemo(
    () =>
      [
        { value: "deadTransport", label: "Mortos Transporte" },
        { value: "numberOfSlaughteredBirds", label: "Numero Aves Abatidas" },
        { value: "liveWeight", label: "Peso Vivo" },
        { value: "discountAddOn", label: "Desconto/ Acrescimo" },
        { value: "averageWeightKg", label: "Peso Medio kg" },
        { value: "batchAge", label: "Idade lote" },
        { value: "density", label: "Densidade/ m2" },
        { value: "deadWeight", label: "Peso Mortos" },
        { value: "aerosaculiteCt", label: "AEROSACULITE-CT" },
        { value: "arthritisCt", label: "ARTRITE - CT" },
        { value: "disgustingLookCt", label: "ASPECTO REPUGNANTE-CT" },
        { value: "cachexiaCt", label: "CAQUEXIA-CT" },
        { value: "contaminationCt", label: "CONTAMINACAO - CT" },
        { value: "inadequateBleedingCt", label: "SANGRIA INADEQ.-CT" },
        {
          value: "septcemiaColibacillosisCt",
          label: "SEPTICEMIA (colibacilose)-CT",
        },
        { value: "asciticSyndrome", label: "SINDROME ASCITICA-CT" },
        { value: "headsCondenTotal", label: "Cabecas Conden Total" },
        { value: "percentCondenTotal", label: "% Conden Total" },
        {
          value: "remnantOfInfectiousBronchitis",
          label: "RESQUÍCIO DE BRONQUITE INFECCIOSA",
        },
        { value: "wingTip", label: "PONTA DA ASA" },
        { value: "condLiverCp", label: "COND. FIGADO - CP" },
        {
          value: "inflammatoryLesionAbscessCp",
          label: "LESÃO INFLAMATÓRIA (abcesso)-CP",
        },
        { value: "aerosaculiteCp", label: "AEROSACULITE-CP" },
        { value: "arthritisCp", label: "ARTRITE-CP" },
        { value: "cellulitisCp", label: "CELULITE-CP" },
        { value: "colibacillosisCp", label: "COLIBACILOSE CP" },
        { value: "contaminationCp", label: "CONTAMINACOES - CP" },
        {
          value: "traumaInjuryDermatosisCp",
          label: "LESÃO TRAUMÁTICA (contusões) - CP",
        },
        {
          value: "skynInjuryDermatosisCp",
          label: "LESÃO DE PELE (dermatose)- CP",
        },
        { value: "excessiveScaldingCp", label: "ESCALDAGEM EXCESSIVA-CP" },
        { value: "neoplasmCp", label: "NEOPLASIA-CP" },
        {
          value: "inflammatoryLesionSalpingitisCp",
          label: "LESÃO INFLAMATÓRIA (salpingite) - CP",
        },
        { value: "inadequateBleedingCp", label: "SANGRIA INADEQ.-CP" },
        { value: "asciticSyndromeCp", label: "SINDROME ASCITICA-CP" },
        { value: "cranialDorsalMyopathy", label: "MIOPATIA DORSAL CRANIAL" },
        { value: "myopathyCp", label: "MIOPATIA - CP" },
        { value: "myositisCp", label: "MIOSITE - CP" },
        { value: "sifCondPartialWeight", label: "SIF - COND. PARCIAL (Peso)" },
        { value: "pawCallusesCp", label: " CALOS DE PATA-CP" },
        { value: "rationInTheChatCp", label: "RAÇÃO NO PAPO-CP" },
        { value: "chestCallusesCp", label: "CALOS DE PEITO-CP" },
        { value: "cannibalismCp", label: "CANIBALISMO-CP" },
        { value: "eviscerRetardCp", label: "EVISCER. RETARD.-CP" },
        { value: "cabCondenPartial", label: "Cab Conden Parcial" },
        { value: "percentCondenPartial", label: "% Conden Parcial" },
        {
          value: "cabContenPartialAndTotal",
          label: "Cab Conden parcial + total",
        },
        {
          value: "percentCondenPartialAndTotal",
          label: "% Conden parcial + total",
        },
        { value: "", label: "Cliente" },
      ].sort((a, b) => a.label.localeCompare(b.label)),
    []
  );

  const [dateRange, setDateRange] = useState([
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: "selection",
    },
  ]);

  const [input, setInput] = useState({
    group: "Semanal",
    createdBy: null,
    customer: props.match.params.id,
    age: [0, 60],
    injuryType: "digestive",
    lineage: "",
    gender: "",
    technicalManager: null,
    broilerList: [],
    nutritionList: [],
  });

  const [broilerInput, setBroilerInput] = useState("");

  const [filter, setFilter] = useState("all");

  const [filterPerPeriod, setFilterPerPeriod] = useState("");

  const [FarmDataFilter, setFarmDataFilter] = useState("batchAge");

  const [reportConsolidated, setReportConsolidated] = useState({});

  const [selection, setSelection] = useState({
    x: "cabContenPartialAndTotal",
    y: "headsCondenTotal",
  });

  const [responseReport, setResponseReport] = useState({
    perPeriod: [],
    totalBirds: 0,
    totalPerInjury: {},
    totalPerScore: {},
    customer: props.match.params.id,
    createdBy: null,
    injuryType: "digestive",
    group: "Semanal",
    broilerList: [],
    nutritionList: [],
  });

  const [generateReport, setGenerateReport] = useState(true);

  const [loading, setLoading] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  // const [farmData, setFarmData] = useState(initialFarmData);

  const [customer, setCustomer] = useState({});

  const [responseConsolidated, setResponseConsolidated] = useState({
    graph: [],
  });

  const [runConsolidated, setRunConsolidated] = useState(false);

  const [ct, setCt] = useState({});

  const [cp, setCp] = useState({});

  const [totalSlaughter, setTotalSlaughter] = useState({});

  const [nutritionInput, setNutritionInput] = useState("");

  const [nutritionList, setNutritionList] = useState([]);

  const filterHandler = (e) => {
    setFilter(e.target.value);
    setRunConsolidated(true);
  };

  const selectionHandler = (key, value) => {
    const copySelection = { ...selection };

    copySelection[key] = value;

    setSelection(copySelection);
    setRunConsolidated(true);
  };

  const broilerInputHandler = (value) => {
    setInput((prevState) => {
      const nextState = JSON.parse(JSON.stringify(prevState));
      nextState.broilerList.push(value);
      return nextState;
    });
  };

  const nutritionInputHandler = (value) => {
    setInput((prevState) => {
      const nextState = JSON.parse(JSON.stringify(prevState));
      nextState.nutritionList.push(value);
      return nextState;
    });
  };

  const reportHandler = () => {
    const query = { ...input, dateRange };
    setLoading(true);
    props.history.replace(
      `/customer/connect/slaughter/report/${
        props.match.params.id
      }/${JSON.stringify(query)}`
    );
    setGenerateReport(true);
  };

  const getReportlabels = () =>
    responseReport.perPeriod.map((item) => {
      return [
        (responseReport.group === "Semanal"
          ? `Semana ${item.week}`
          : months[item.month]) +
          " / " +
          item.year,
        `De ${convertDateToString(
          responseReport.group === "Semanal"
            ? item.startOfWeek
            : item.startOfMonth
        )} até ${convertDateToString(
          responseReport.group === "Semanal" ? item.endOfWeek : item.endOfMonth
        )}`,
        `${item.totalBirds} Aves Abatidas`,
        responseReport.createdBy ? responseReport.createdBy : "",
      ];
    });

  const getReportData = useCallback(
    (injuryType) => {
      const response = {
        datasets: [
          {
            label: "Score-Abate",
            type: "line",
            data: responseReport.perPeriod.map((item) =>
              item.totalScore.toFixed(2)
            ),
            fill: false,
            borderColor: getColor("#007bff", 0.7),
            backgroundColor: getColor("#007bff", 0.7),
            pointBorderColor: "#007bff",
            pointBackgroundColor: "#007bff",
            pointHoverBackgroundColor: getColor("#007bff", 0.9),
            pointHoverBorderColor: getColor("#007bff", 0.9),
            yAxisID: "y-axis-1",
          },
          {
            label: "Score-Inspeção-Dorsal",
            type: "line",
            data: responseReport.perPeriod.map((item) =>
              item.totalDorsalInspection.toFixed(2)
            ),
            fill: false,
            borderColor: getColor("#389caf", 0.7),
            backgroundColor: getColor("#389caf", 0.7),
            pointBorderColor: "#389caf",
            pointBackgroundColor: "#389caf",
            pointHoverBackgroundColor: getColor("#389caf", 0.9),
            pointHoverBorderColor: getColor("#389caf", 0.9),
            yAxisID: "y-axis-1",
          },
          {
            label: "Score-Inspeção-Ventral",
            type: "line",
            data: responseReport.perPeriod.map((item) =>
              item.totalVentralInspection.toFixed(2)
            ),
            fill: false,
            borderColor: getColor("#f89caf", 0.7),
            backgroundColor: getColor("#f89caf", 0.7),
            pointBorderColor: "#f89caf",
            pointBackgroundColor: "#f89caf",
            pointHoverBackgroundColor: getColor("#f89caf", 0.9),
            pointHoverBorderColor: getColor("#f89caf", 0.9),
            yAxisID: "y-axis-1",
          },
          {
            label: "Score-Inspeção-Interna",
            type: "line",
            data: responseReport.perPeriod.map((item) =>
              item.totalInternalInspection.toFixed(2)
            ),
            fill: false,
            borderColor: getColor("#1a0caf", 0.7),
            backgroundColor: getColor("#1a0caf", 0.7),
            pointBorderColor: "#1a0caf",
            pointBackgroundColor: "#1a0caf",
            pointHoverBackgroundColor: getColor("#1a0caf", 0.9),
            pointHoverBorderColor: getColor("#1a0caf", 0.9),
            yAxisID: "y-axis-1",
          },
          {
            label: "Score-Calo-de-Patas",
            type: "line",
            data: responseReport.perPeriod.map((item) =>
              item.totalLegCorns.toFixed(2)
            ),
            fill: false,
            borderColor: getColor("#7a0caf", 0.7),
            backgroundColor: getColor("#7a0caf", 0.7),
            pointBorderColor: "#7a0caf",
            pointBackgroundColor: "#7a0caf",
            pointHoverBackgroundColor: getColor("#7a0caf", 0.9),
            pointHoverBorderColor: getColor("#7a0caf", 0.9),
            yAxisID: "y-axis-1",
          },
        ],
      };
      if (injuryType) {
        response.datasets = response.datasets.filter(
          ({ label }) => label === injuryType.label
        );
      }

      let keys = [
        "ventralInspection.skinBreakage",
        "ventralInspection.chestSkinBurn",
        "ventralInspection.celluliteBelowChest",
        "ventralInspection.chestSkinBleeding",
        "ventralInspection.bleedingWings",
        "ventralInspection.displacementWings",
        "ventralInspection.fractureWings",
        "dorsalInspection.tailSkinBreakage",
        "dorsalInspection.dermatitis",
        "dorsalInspection.scratchedWoundNew",
        "dorsalInspection.scratchedWoundOld",
        "dorsalInspection.bleedingThighs",
        "dorsalInspection.thighFracture",
        "dorsalInspection.dorsalMyopathy",
        "internalInspection.punctateChestHemorrhage",
        "internalInspection.thighsPunctiformHemorrhage",
        "internalInspection.whiteStreak",
        "internalInspection.woodChest",
        "internalInspection.chestSpaghetti",
        "legCorns",
      ];
      if (injuryType) {
        keys = keys.filter((key) => key.indexOf(injuryType.key) > -1);
      }
      keys.forEach((key) => {
        const arrayKeys = key.split(".");
        const itemConfig =
          key === "legCorns"
            ? slaughterConfig[key]
            : slaughterConfig[arrayKeys[0]][arrayKeys[1]];

        response.datasets.push({
          label: itemConfig.label,
          yAxisID: "y-axis-2",
          type: "bar",
          backgroundColor: responseReport.perPeriod.map((item) => {
            return getColor(itemConfig.color, 0.7);
          }),
          borderColor: responseReport.perPeriod.map((item) => {
            return getColor(itemConfig.color, 0.8);
          }),
          hoverBackgroundColor: responseReport.perPeriod.map((item) => {
            return getColor(itemConfig.color, 0.9);
          }),
          hoverBorderColor: responseReport.perPeriod.map((item) => {
            return getColor(itemConfig.color, 1);
          }),
          borderWidth: 1,
          light: responseReport.perPeriod.map((item) => {
            return key === "legCorns"
              ? (+(item[`${key}.light`] / item.totalBirds) * 100).toFixed(1)
              : (
                  +(item[`${arrayKeys[1]}.light`] / item.totalBirds) * 100
                ).toFixed(1);
          }),
          severe: responseReport.perPeriod.map((item) => {
            return key === "legCorns"
              ? (+(item[`${key}.severe`] / item.totalBirds) * 100).toFixed(1)
              : (
                  +(item[`${arrayKeys[1]}.severe`] / item.totalBirds) * 100
                ).toFixed(1);
          }),
          data: responseReport.perPeriod.map((item) => {
            return key === "legCorns"
              ? (
                  +(item[key + filterPerPeriod] / item.totalBirds) * 100
                ).toFixed(1)
              : (
                  +(item[arrayKeys[1] + filterPerPeriod] / item.totalBirds) *
                  100
                ).toFixed(1);
          }),
        });
      });
      return response;
    },
    [responseReport, filterPerPeriod]
  );

  const getReportFarmData = () => {
    let color = getColor();
    const index = farmDataFilters
      .map(({ value }) => value)
      .indexOf(FarmDataFilter);
    const response = [
      {
        label: farmDataFilters[index].label,
        data: responseReport.perPeriod.map((item) => item[FarmDataFilter]),
        backgroundColor: getColor(color, 0.4),
        borderColor: color,
        borderDash: [],
        fill: false,
        lineTension: 0.1,
        borderDashOffset: 0.0,
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "y-axis-1",
      },
    ];
    color = getColor();
    response.push({
      label: "Score",
      data: responseReport.perPeriod.map((item) => item.totalScore),
      backgroundColor: getColor(color, 0.4),
      borderColor: color,
      borderDash: [],
      fill: false,
      lineTension: 0.1,
      borderDashOffset: 0.0,
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      yAxisID: "y-axis-2",
    });
    return response;
  };

  const createBoxScores = () => {
    const keys = [
      "ventralInspection.skinBreakage",
      "ventralInspection.chestSkinBurn",
      "ventralInspection.celluliteBelowChest",
      "ventralInspection.chestSkinBleeding",
      "ventralInspection.bleedingWings",
      "ventralInspection.displacementWings",
      "ventralInspection.fractureWings",
      "dorsalInspection.tailSkinBreakage",
      "dorsalInspection.dermatitis",
      "dorsalInspection.scratchedWoundNew",
      "dorsalInspection.scratchedWoundOld",
      "dorsalInspection.bleedingThighs",
      "dorsalInspection.thighFracture",
      "dorsalInspection.dorsalMyopathy",
      "internalInspection.punctateChestHemorrhage",
      "internalInspection.thighsPunctiformHemorrhage",
      "internalInspection.whiteStreak",
      "internalInspection.woodChest",
      "internalInspection.chestSpaghetti",
      "legCorns",
    ];
    let columns = [];
    const rows = [];
    keys.forEach((key, index) => {
      columns.push(
        <React.Fragment key={key}>
          <BoxScore params={`${key}`} response={responseReport} />
        </React.Fragment>
      );
      if (columns.length === 4 || index === keys.length - 1) {
        for (let i = columns.length; i < 4; i++) {
          columns.push(<Col key={`${key}${i}`}></Col>);
        }
        rows.push(<Row key={`row${key}`}>{columns}</Row>);
        columns = [];
      }
    });

    return rows;
  };

  const exportPDF = () => {
    setModalShow(true);
  };

  useEffect(() => {
    const getNutritionList = async () => {
      const responseNutritionList = await api.get("necropsyconfiglists");
      setNutritionList(
        responseNutritionList.data.filter(({ key }) => key === "nutrition")
      );
    };
    getNutritionList();
  }, []);

  useEffect(() => {
    const loadReport = async () => {
      const params = JSON.parse(props.match.params.query);
      const responseSlaughter = await api
        .post("/connect/broiler/reportslaughter", {
          ...props.match.params,
        })
        .catch((e) => {
          return e;
        });
      if (!responseSlaughter.data) {
        setLoading(false);
        return Swal.fire("Relatório", responseSlaughter.message, "error");
      }

      setResponseReport(JSON.parse(JSON.stringify(responseSlaughter.data)));
      setInput({
        group: params.group,
        customer: props.match.params.id,
        broilerList: params.broilerList,
        nutritionList: params.nutritionList,
      });
      const responseConsolidated = await api
        .post("broilerconsolidatedslaughter/report", props.match.params)
        .catch((e) => {
          return e;
        });
      responseSlaughter.data.perPeriod.forEach(
        ({ totalScore, week, month, year }) => {
          const index = responseConsolidated.data.graph
            .map(({ key }) => key)
            .indexOf(
              `all;Consolidado;${month};${year}${
                params.group === "Semanal" ? ";" + week : ""
              }`
            );
          if (index > -1) {
            responseConsolidated.data.graph[index].score = +totalScore;
          }
        }
      );

      setResponseConsolidated(responseConsolidated.data);
      reportConsolidatedHandler(responseConsolidated.data);

      setDateRange([
        {
          startDate: startOfDay(new Date(params.dateRange[0].startDate)),
          endDate: endOfDay(new Date(params.dateRange[0].endDate)),
          key: "selection",
        },
      ]);
      /*  const responseFarmData = await api.get(
        `broilernecropsy/historic/${params.customer}`
      ); */
      //setFarmData(getInitialObject(initialFarmData, responseFarmData.data));
      setLoading(false);
    };

    const reportConsolidatedHandler = (data) => {
      if (!data) return;
      const labels = [
        ...new Set(
          data.graph
            .filter((item) => item.key.split(";")[0] === filter)
            .map((item) => {
              const keys = item.key.split(";");
              return `${keys[2]};${keys[3]}` + (keys[4] ? `;${keys[4]}` : "");
            })
        ),
      ];
      const datasets = [];
      data.graph
        .filter((item) => item.key.split(";")[0] === filter)
        .forEach((item) => {
          let label = `${item.key.split(";")[1]}-${
            selections[
              selections.map(({ value }) => value).indexOf(selection.x)
            ].label
          }`;
          const index = datasets.map(({ label }) => label).indexOf(label);
          if (index < 0) {
            let color = getColor();
            datasets.push({
              label,
              data: labels.map((date) => {
                const index = data.graph
                  .map(({ key }) => key)
                  .indexOf(`${filter};${label.split("-")[0]};${date}`);
                return index > -1
                  ? (
                      data.graph[index][selection.x] /
                      (selection.x === "score" ? 1 : data.graph[index].count)
                    ).toFixed(3)
                  : null;
              }),
              backgroundColor: getColor(color, 0.4),
              borderColor: color,
              borderDash: [],
              fill: false,
              lineTension: 0.1,
              borderDashOffset: 0.0,
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              yAxisID: "y-axis-1",
            });
            color = getColor();
            label = `${item.key.split(";")[1]}-${
              selections[
                selections.map(({ value }) => value).indexOf(selection.y)
              ].label
            }`;
            datasets.push({
              label,
              data: labels.map((date) => {
                const index = data.graph
                  .map(({ key }) => key)
                  .indexOf(`${filter};${label.split("-")[0]};${date}`);
                return index > -1
                  ? (
                      data.graph[index][selection.y] /
                      (selection.y === "score" ? 1 : data.graph[index].count)
                    ).toFixed(3)
                  : null;
              }),
              backgroundColor: getColor(color, 0.4),
              borderColor: color,
              borderDash: [],
              fill: false,
              lineTension: 0.1,
              borderDashOffset: 0.0,
              pointBackgroundColor: "#fff",
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              yAxisID: "y-axis-2",
            });
          }
        });

      setReportConsolidated({
        labels: labels.map(
          (item) =>
            (item.split(";")[2] ? `Semana ${item.split(";")[2]} - ` : "") +
            `${months[+item.split(";")[0]]} ${item.split(";")[1]}`
        ),
        datasets,
      });

      const totalSlaughterData = {
        labels: [],
        datasets: [
          {
            label: "Total de aves Abatidas",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };

      const totalSlaughterSum = Object.keys(data.totalSlaughter)
        .map((key) => data.totalSlaughter[key])
        .reduce((a, b) => a + b, 0);

      Object.keys(data.totalSlaughter).forEach((param) => {
        totalSlaughterData.labels.push(
          selections[selections.map(({ value }) => value).indexOf(param)]
            .label +
            " - " +
            (
              Math.round(
                (data.totalSlaughter[param] / totalSlaughterSum) * 100 * 100
              ) / 100
            )
              .toString()
              .replace(".", ",") +
            "%"
        );
        totalSlaughterData.datasets[0].data.push(data.totalSlaughter[param]);
        const color = getColor();
        totalSlaughterData.datasets[0].backgroundColor.push(
          getColor(color, 0.6)
        );
        totalSlaughterData.datasets[0].borderColor.push(getColor(color, 1));
      });

      setTotalSlaughter(totalSlaughterData);

      const ctData = {
        labels: [],
        datasets: [
          {
            label: "Condenação Total",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };

      const ctSum = Object.keys(data.ct)
        .map((key) => data.ct[key])
        .reduce((a, b) => a + b, 0);

      Object.keys(data.ct).forEach((param) => {
        ctData.labels.push(
          selections[selections.map(({ value }) => value).indexOf(param)]
            .label +
            " - " +
            (Math.round((data.ct[param] / ctSum) * 100 * 100) / 100)
              .toString()
              .replace(".", ",") +
            "%"
        );
        ctData.datasets[0].data.push(data.ct[param]);
        const color = getColor();
        ctData.datasets[0].backgroundColor.push(getColor(color, 0.6));
        ctData.datasets[0].borderColor.push(getColor(color, 1));
      });

      setCt(ctData);

      const cpData = {
        labels: [],
        datasets: [
          {
            label: "Condenação Parcial",
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1,
          },
        ],
      };

      const cpSum = Object.keys(data.cp)
        .map((key) => data.cp[key])
        .reduce((a, b) => a + b, 0);

      Object.keys(data.cp).forEach((param) => {
        cpData.labels.push(
          selections[selections.map(({ value }) => value).indexOf(param)]
            .label +
            " - " +
            (Math.round((data.cp[param] / cpSum) * 100 * 100) / 100)
              .toString()
              .replace(".", ",") +
            "%"
        );
        cpData.datasets[0].data.push(data.cp[param]);
        const color = getColor();
        cpData.datasets[0].backgroundColor.push(getColor(color, 0.6));
        cpData.datasets[0].borderColor.push(getColor(color, 1));
      });

      setCp(cpData);
    };

    if (generateReport && props.match.params.query !== "{}") {
      setGenerateReport(false);
      setLoading(true);
      loadReport();
    }
    if (runConsolidated) {
      setRunConsolidated(false);
      reportConsolidatedHandler(responseConsolidated);
    }
  }, [
    generateReport,
    props.match.params,
    initialFarmData,
    customer,
    runConsolidated,
    selections,
    customer,
    filter,
    responseConsolidated,
    selection,
  ]);

  useEffect(() => {
    const getCustomerName = async () => {
      const responseCustomer = await api.get(
        `connect/customer/${props.match.params.id}`
      );
      setCustomer(responseCustomer.data);
      setLoading(false);
    };
    if (!customer._id) {
      getCustomerName();
    }
  }, [props.match.params.id, customer._id]);

  useEffect(() => {
    if (props.match.params.query === "{}") {
      setDateRange([
        {
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
          key: "selection",
        },
      ]);
      setInput({
        group: "Semanal",
        customer: props.match.params.id,
        broilerList: [],
        nutritionList: [],
      });

      setResponseReport({
        perPeriod: [],
        totalBirds: 0,
        totalPerInjury: {},
        totalPerScore: {},
        customer: props.match.params.id,
        createdBy: null,
        group: "Semanal",
        broilerList: [],
        nutritionList: [],
      });
    }
  }, [props.match.params]);

  return (
    <>
      {modalShow ? (
        <Export
          show={modalShow}
          onHide={() => setModalShow(false)}
          location={props.location}
          responseReport={responseReport}
          customer={props.match.params.id}
          broilerList={JSON.parse(props.match.params.query).broilerList}
          id={props.match.params.reportId}
        />
      ) : null}
      <SubHeader
        {...props}
        title={"Relatório - Frango de Corte - Abate - " + customer.name}
        breadcrumbs={[
          {
            label: customer.name,
            to: "/connect/customer/" + props.match.params.id,
          },
          {
            label: "Relatório - Frango de Corte - Abate ",
          },
        ]}
        icon={<Icons type="lineChart" size="24" color="#fff" />}
        toolbar={responseReport.perPeriod[0] ? ["exportReport"] : null}
        exportReport={exportPDF}
      />

      <Row>
        <Col size={1}>
          <Block>
            <BlockHeader>Filtros</BlockHeader>
            <BlockBody>
              <Input
                type="select"
                label="Agrupar"
                item={input}
                setItem={setInput}
                params="group"
                options={[{ value: "Semanal" }, { value: "Mensal" }]}
                disabled={loading}
              />

              <Input
                item={broilerInput}
                onChange={(e) => broilerInputHandler(e.target.value)}
                type="select"
                setItem={setBroilerInput}
                placeholder="Selecione as opções"
                label="Histórico de programa"
                options={broiler
                  .filter(
                    ({ value }) => input.broilerList.indexOf(value) === -1
                  )
                  .map((item) => item)}
              />
              <HistoricOptionContent>
                {input.broilerList.map((item, index) => (
                  <HistoricOption key={item}>
                    {broiler.filter(({ value }) => value === item)[0].label}
                    <Icons
                      type="close"
                      size={10}
                      color="#fff"
                      onClick={() =>
                        setInput((prevState) => {
                          const nextState = JSON.parse(
                            JSON.stringify(prevState)
                          );
                          nextState.broilerList.splice(index, 1);
                          return nextState;
                        })
                      }
                    ></Icons>
                  </HistoricOption>
                ))}
              </HistoricOptionContent>
              <Input
                type="select"
                label="Nutrição"
                item={nutritionInput}
                setItem={setNutritionInput}
                onChange={(e) => nutritionInputHandler(e.target.value)}
                placeholder="Selecione a nutrição"
                params="nutrition"
                options={nutritionList
                  .filter(
                    ({ value }) => input.nutritionList?.indexOf(value) === -1
                  )
                  .map((item) => item)}
                disabled={loading}
              />
              <HistoricOptionContent>
                {input.nutritionList?.map((item, index) => (
                  <HistoricOption key={item}>
                    {
                      nutritionList.filter(({ value }) => value === item)[0]
                        .value
                    }
                    <Icons
                      type="close"
                      size={10}
                      color="#fff"
                      onClick={() =>
                        setInput((prevState) => {
                          const nextState = JSON.parse(
                            JSON.stringify(prevState)
                          );
                          nextState.nutritionList.splice(index, 1);
                          return nextState;
                        })
                      }
                    ></Icons>
                  </HistoricOption>
                ))}
              </HistoricOptionContent>
              <Input
                item={input}
                setItem={setInput}
                params="lineage"
                type="select"
                placeholder="Todas"
                label="Linhagem"
                options={["Cobb", "Ross", "Hubbard", "Misto"].map((item) => ({
                  value: item,
                }))}
              />
              <Input
                item={input}
                setItem={setInput}
                params="gender"
                type="select"
                placeholder="Todas"
                label="Sexo"
                options={["Macho", "Femea", "Misto"].map((item) => ({
                  value: item,
                }))}
              />
              <div className="form-group">
                <label className="form-label">Período de Análise</label>
                <DateRange
                  className="date-block"
                  editableDateInputs={true}
                  onChange={(item) => setDateRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  locale={ptBR}
                  endDatePlaceholder="Até"
                  startDatePlaceholder="De"
                  dateDisplayFormat="dd/MM/yyyy"
                  color="#009870"
                  weekdayDisplayFormat="eeeee"
                  rangeColors={["#009870"]}
                  disabled={loading}
                />
              </div>
              <Separator></Separator>
              <ReportButton
                color="white"
                bg="default"
                border="default"
                disabled={loading}
                onClick={reportHandler}
              >
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#ffffff"
                    height={19}
                    width={19}
                  />
                ) : (
                  <>
                    <i className="fas fa-cogs" /> Gerar Relatório
                  </>
                )}
              </ReportButton>
            </BlockBody>
          </Block>
        </Col>
        <Col size={3}>
          {props.match.params.query !== "{}" &&
            responseConsolidated &&
            responseConsolidated.graph[0] &&
            !loading && (
              <Block id="slaughterTotalGraph">
                <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
                  Total de Aves abatidas | Condenação Total / Parcial | De{" "}
                  {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
                  {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
                  {responseReport.customer
                    ? ` | ${responseReport.customer}`
                    : ""}
                </BlockHeader>
                <BlockBody>
                  <BlockBodyContent>
                    <Pie
                      data={totalSlaughter}
                      options={{
                        legend: {
                          position: "right",
                        },
                        tooltips: {
                          bodyFontColor: "#fff",
                          titleFontStyle: "bold",
                          displayColors: false,
                          callbacks: {
                            label: function (tooltipItem, chart) {
                              const { data } =
                                chart.datasets[tooltipItem.datasetIndex];
                              return data[tooltipItem.index];
                            },
                            title: function (tooltipItem, data) {
                              return data.labels[tooltipItem[0].index];
                            },
                          },
                        },
                      }}
                    />
                  </BlockBodyContent>
                </BlockBody>
              </Block>
            )}
        </Col>
      </Row>
      {props.match.params.query !== "{}" &&
        responseConsolidated &&
        responseConsolidated.graph[0] &&
        !loading && (
          <>
            <Block id="cTotal">
              <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
                Consolidado | Condenação Total | De{" "}
                {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
                {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
                {responseReport.customer ? ` | ${responseReport.customer}` : ""}
              </BlockHeader>
              <BlockBody>
                <BlockBodyContent>
                  <Pie
                    data={ct}
                    options={{
                      legend: {
                        position: "right",
                      },
                      tooltips: {
                        bodyFontColor: "#fff",
                        titleFontStyle: "bold",
                        displayColors: false,
                        callbacks: {
                          label: function (tooltipItem, chart) {
                            const { data } =
                              chart.datasets[tooltipItem.datasetIndex];
                            return data[tooltipItem.index];
                          },
                          title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                          },
                        },
                      },
                    }}
                  />
                </BlockBodyContent>
              </BlockBody>
            </Block>

            <Block id="cPartial">
              <BlockHeader>
                Consolidado | Condenação Parcial | De{" "}
                {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
                {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
                {responseReport.customer ? ` | ${responseReport.customer}` : ""}
              </BlockHeader>
              <BlockBody>
                <Pie
                  data={cp}
                  options={{
                    legend: {
                      position: "right",
                    },
                    tooltips: {
                      bodyFontColor: "#fff",
                      titleFontStyle: "bold",
                      displayColors: false,
                      callbacks: {
                        label: function (tooltipItem, chart) {
                          const { data } =
                            chart.datasets[tooltipItem.datasetIndex];
                          return data[tooltipItem.index];
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </BlockBody>
            </Block>
          </>
        )}
      {props.match.params.query !== "{}" &&
        responseConsolidated &&
        responseConsolidated.graph[0] &&
        !loading && (
          <Block>
            <BlockHeader>
              Resultado Consolidado:{" "}
              {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
              {format(dateRange[0].endDate, "dd-MM-yyyy")}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                <Row>
                  <Col>
                    <Input
                      item={filter}
                      setItem={setFilter}
                      onChange={filterHandler}
                      options={filters.map((item) => item)}
                      label="Filtro"
                      type="select"
                    />
                  </Col>
                  <Col>
                    <Input
                      onChange={(e) => selectionHandler("x", e.target.value)}
                      options={selections.map((item) => item)}
                      name="x"
                      value={selection.x}
                      label="Resultado 1"
                      type="select"
                    />
                  </Col>
                  <Col>
                    <Input
                      onChange={(e) => selectionHandler("y", e.target.value)}
                      options={selections.map((item) => item)}
                      value={selection.y}
                      label="Resultado 2"
                      type="select"
                    />
                  </Col>
                </Row>
              </BlockBodyContent>
              <BlockBodyContent id="graphConsolidado">
                <Line
                  data={{ datasets: reportConsolidated.datasets }}
                  options={{
                    labels: reportConsolidated.labels,
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: reportConsolidated.labels,
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: false,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: false,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "right",
                    },
                  }}
                />
              </BlockBodyContent>
            </BlockBody>
          </Block>
        )}
      {responseReport.perPeriod[0] &&
      !loading &&
      props.match.params.query !== "{}" ? (
        <>
          <Block>
            <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
              Lesões | Score | De {format(dateRange[0].startDate, "dd-MM-yyyy")}{" "}
              Até {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                <Row>
                  <Col>
                    <Input
                      item={filterPerPeriod}
                      setItem={setFilterPerPeriod}
                      options={[
                        { label: "Total", value: "" },
                        { label: "Severas", value: ".severe" },
                        { label: "Leves", value: ".light" },
                      ]}
                      label="Filtro"
                      type="select"
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </BlockBodyContent>
              <BlockBodyContent id="graph">
                <Bar
                  data={getReportData()}
                  options={{
                    labels: getReportlabels(),

                    elements: {
                      line: {
                        fill: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                            max: 10,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "bottom",
                    },
                    showAllTooltips: true,
                    tooltips: {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      bodyFontColor: "#48465b",
                      titleFontStyle: "normal",
                      displayColors: false,
                      callbacks: {
                        label: function (tooltipItem, chart) {
                          const { label, data, severe, light } =
                            chart.datasets[tooltipItem.datasetIndex];

                          return `${label}: (Total:${data[tooltipItem.index]
                            .toString()
                            .replace(".", ",")}%, Severas: ${
                            severe[tooltipItem.index]
                          }%, Leves: ${light[tooltipItem.index]}%)`;
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </BlockBodyContent>
            </BlockBody>
          </Block>
          <Block id="graphDorsalInspection">
            <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
              Inspeção Dorsal | Score | De{" "}
              {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
              {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                <Bar
                  data={getReportData({
                    label: "Score-Inspeção-Dorsal",
                    key: "dorsalInspection",
                  })}
                  options={{
                    labels: getReportlabels(),

                    elements: {
                      line: {
                        fill: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                            max: 10,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "bottom",
                    },
                    showAllTooltips: true,
                    tooltips: {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      bodyFontColor: "#48465b",
                      titleFontStyle: "normal",
                      displayColors: false,
                      callbacks: {
                        label: function (tooltipItem, chart) {
                          const { label, data } =
                            chart.datasets[tooltipItem.datasetIndex];

                          return (
                            (label === "Score Connect (Digestivo)"
                              ? ""
                              : label + ": ") +
                            data[tooltipItem.index].toString().replace(".", ",")
                          );
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </BlockBodyContent>
            </BlockBody>
          </Block>
          <Block id="graphVentralInspection">
            <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
              Inspeção Ventral | Score | De{" "}
              {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
              {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                <Bar
                  data={getReportData({
                    label: "Score-Inspeção-Ventral",
                    key: "ventralInspection",
                  })}
                  options={{
                    labels: getReportlabels(),

                    elements: {
                      line: {
                        fill: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                            max: 10,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "bottom",
                    },
                    showAllTooltips: true,
                    tooltips: {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      bodyFontColor: "#48465b",
                      titleFontStyle: "normal",
                      displayColors: false,
                      callbacks: {
                        label: function (tooltipItem, chart) {
                          const { label, data } =
                            chart.datasets[tooltipItem.datasetIndex];
                          return (
                            (label === "Score Connect (Digestivo)"
                              ? ""
                              : label + ": ") +
                            data[tooltipItem.index].toString().replace(".", ",")
                          );
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </BlockBodyContent>
            </BlockBody>
          </Block>
          <Block id="graphInternalInspection">
            <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
              Inspeção Interna | Score | De{" "}
              {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
              {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                <Bar
                  data={getReportData({
                    label: "Score-Inspeção-Interna",
                    key: "internalInspection",
                  })}
                  options={{
                    labels: getReportlabels(),

                    elements: {
                      line: {
                        fill: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                            max: 10,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "bottom",
                    },
                    showAllTooltips: true,
                    tooltips: {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      bodyFontColor: "#48465b",
                      titleFontStyle: "normal",
                      displayColors: false,
                      callbacks: {
                        label: function (tooltipItem, chart) {
                          const { label, data } =
                            chart.datasets[tooltipItem.datasetIndex];
                          return (
                            (label === "Score Connect (Digestivo)"
                              ? ""
                              : label + ": ") +
                            data[tooltipItem.index].toString().replace(".", ",")
                          );
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </BlockBodyContent>
            </BlockBody>
          </Block>
          <Block id="graphLegCorns">
            <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/bg-2-basf.jpg">
              Calo de Patas | Score | De{" "}
              {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
              {format(dateRange[0].endDate, "dd-MM-yyyy")} | {input.group}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                <Bar
                  data={getReportData({
                    label: "Score-Calo-de-Patas",
                    key: "legCorns",
                  })}
                  options={{
                    labels: getReportlabels(),

                    elements: {
                      line: {
                        fill: false,
                      },
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                            max: 10,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "bottom",
                    },
                    showAllTooltips: true,
                    tooltips: {
                      backgroundColor: "rgba(0, 0, 0, 0)",
                      bodyFontColor: "#48465b",
                      titleFontStyle: "normal",
                      displayColors: false,
                      callbacks: {
                        label: function (tooltipItem, chart) {
                          const { label, data } =
                            chart.datasets[tooltipItem.datasetIndex];
                          return (
                            (label === "Score Connect (Digestivo)"
                              ? ""
                              : label + ": ") +
                            data[tooltipItem.index].toString().replace(".", ",")
                          );
                        },
                        title: function (tooltipItem, data) {
                          return data.labels[tooltipItem[0].index];
                        },
                      },
                    },
                  }}
                />
              </BlockBodyContent>
            </BlockBody>
          </Block>
          <Block>
            <BlockHeader>
              Comparativo dados x score:{" "}
              {format(dateRange[0].startDate, "dd-MM-yyyy")} Até{" "}
              {format(dateRange[0].endDate, "dd-MM-yyyy")}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>
              <BlockBodyContent>
                <Row>
                  <Col>
                    <Input
                      item={FarmDataFilter}
                      setItem={setFarmDataFilter}
                      options={farmDataFilters.map((item) => item)}
                      label="Filtro"
                      type="select"
                    />
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </BlockBodyContent>
              <BlockBodyContent id="graphFarmData">
                <Line
                  data={{
                    datasets: getReportFarmData(),
                  }}
                  options={{
                    labels: getReportlabels(),
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          gridLines: {
                            display: false,
                          },

                          labels: getReportlabels(),
                        },
                      ],
                      yAxes: [
                        {
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                        {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                            display: false,
                          },
                          labels: {
                            show: true,
                          },
                          ticks: {
                            beginAtZero: true,
                            max: 10,
                          },
                        },
                      ],
                    },
                    legend: {
                      position: "right",
                    },
                  }}
                />
              </BlockBodyContent>
            </BlockBody>
          </Block>
          <Block id="score">
            <BlockHeader bg="https://zenbytes-public.sfo2.digitaloceanspaces.com/mcassab/menu-top-connect.png">
              Score por Lesão: {format(dateRange[0].startDate, "dd-MM-yyyy")}{" "}
              Até {format(dateRange[0].endDate, "dd-MM-yyyy")}
              {responseReport.customer ? ` | ${responseReport.customer}` : ""}
            </BlockHeader>
            <BlockBody>{createBoxScores()}</BlockBody>
          </Block>

          {JSON.parse(props.match.params.query).broilerList[0] && (
            <HistoricTable
              perPeriod={responseReport.perPeriod}
              type={responseReport.group}
              broiler={broiler}
              broilerList={JSON.parse(props.match.params.query).broilerList}
            />
          )}
        </>
      ) : null}
    </>
  );
}
